import { useContext } from 'react';
import { connect } from 'react-redux';
import { addMiddleware } from 'redux-dynamic-middlewares';
import PropTypes from 'prop-types';
import { compose } from 'underscore';
import { Skeleton } from 'bv-components';
import { ModalWindowContext } from 'bv-contexts';
import { GamesList } from 'casino-components';
import casinoStore, { resetSearch as resetSearchAction } from 'CasinoV2/ducks/casino_store';
import { searchGames, searchMore as searchMoreAction } from 'CasinoV2/ducks/casino_thunks';
import casinoMiddleware from 'CasinoV2/middleware';
import { selectSearch } from 'CasinoV2/selectors/casino_selector';
import NoSearchResults from '../components/no_search_results';
import RecentSearches from '../components/recent_searches';
import useSearchParams from '../hooks/use_search_params';
import useThrottleSearch from '../hooks/use_throttle_search';

const { addReducers, store } = window.reduxState;

if (!store.getState().casinoStore) {
  addReducers({
    casinoStore,
  });

  addMiddleware(
    casinoMiddleware,
  );
}

const CasinoApp = ({
  fetching, games, gameInstances, search, resetSearch, searchMore,
}) => {
  const { searchTerm } = useSearchParams();
  const { showRecentSearches } = useThrottleSearch(search, resetSearch);
  const { scrollableRef } = useContext(ModalWindowContext);

  if (showRecentSearches) return <RecentSearches />;

  if (fetching) return <Skeleton skeletonType="casino-wrapper" hasHeader={false} />;

  if (!games.length) return <NoSearchResults className="search-results-wrapper" />;

  const tracking = { path: 'search' };

  return games.length > 0 && (
    <GamesList
      games={games}
      gameInstances={gameInstances}
      onEndReached={() => { searchMore(searchTerm); }}
      inModal
      scrollableRef={scrollableRef}
      tracking={tracking}
    />
  );
};

CasinoApp.propTypes = {
  fetching: PropTypes.bool.isRequired,
  games: PropTypes.instanceOf(Array).isRequired,
  gameInstances: PropTypes.instanceOf(Array).isRequired,
  search: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
  searchMore: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  search: (term) => dispatch(searchGames('modal')(term)),
  resetSearch: compose(dispatch, resetSearchAction('modal')),
  searchMore: compose(dispatch, searchMoreAction('modal')),
});

export default connect(selectSearch('modal'), mapDispatchToProps)(CasinoApp);
