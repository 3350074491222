import _, {
  compose, property, partial, each, filter, isEqual,
} from 'underscore';

import { dataLayer } from 'bv-helpers';

import { normaliseComponent, normaliseSearchResults } from 'CasinoV2/normalisers';
import { selectComponent } from 'CasinoV2/selectors/casino_selector';

import {
  fetchCatalogJson,
  fetchComponentsJson,
  fetchPersonalizedGamesJson,
  fetchSearchResult,
  getFilteredGames,
} from 'CasinoV2/api';

import {
  setCatalog,
  setComponent,
  updateComponent,
  searchInit,
  setSearchResult,
  resetSearch,
  fetchCatalogInit,
  fetchComponentsInit,
  setComponentsSuccess,
  setFilteredGames,
  fetchFilteredGames,
  resetFilteredGames,
} from './casino_store';

export const fetchCatalog = () => (dispatch) => {
  dispatch(fetchCatalogInit());

  return fetchCatalogJson().then(compose(dispatch, setCatalog));
};

export const fetchComponents = (category, suppliers) => (dispatch, getState) => {
  const { casinoStore: { fetching } } = getState();
  if (fetching) return;

  dispatch(fetchComponentsInit());

  fetchComponentsJson(category, suppliers)
    .then(property('components'))
    .then(partial(each, _, compose(dispatch, setComponent, normaliseComponent)))
    .then(compose(dispatch, setComponentsSuccess));
};

export const filterBySuppliers = (category, suppliers) => (dispatch, getState) => {
  const { casinoStore: { fetching } } = getState();
  if (fetching) return;

  fetchComponentsJson(category, suppliers)
    .then(property('components'))
    .then(partial(filter, _, compose(partial(isEqual, 'CurrentCategoryGamesComponent'), property('type'))))
    .then(partial(each, _, compose(dispatch, updateComponent, normaliseComponent)));
};

let currentSearchTerm;

export const searchGames = (component) => (term = '', page = 1) => (dispatch) => {
  currentSearchTerm = term;

  dispatch((page === 1 ? resetSearch : searchInit)(component)());

  if (page === 1) {
    dataLayer.push({
      event: 'Site Search',
      Query: term,
      Search_Product: 'CASINO',
      Search_Source: component,
    });
  }

  return fetchSearchResult(term, page)
    .then((result) => {
      // We need this due to not having requests cancelation
      // redux-observable?
      if (currentSearchTerm !== term) return;

      const results = normaliseSearchResults(result);
      const { result: { totalEntries } } = results;
      if (page === 1) {
        dataLayer.push({
          event: 'Search Results Shown',
          Query: term,
          Search_Product: 'CASINO',
          Active_Tab: null,
          Result_Count: totalEntries,
          Search_Source: component,
        });
      }
      dispatch(setSearchResult(component)(results));
    });
};

export const searchMore = (component) => (term) => (dispatch, getState) => {
  const { casinoStore: { search: { [component]: { fetching, nextPage } } } } = getState();

  if (!fetching && nextPage) dispatch(searchGames(component)(term, nextPage));
};

export const fetchPersonalizedGames = (id, type) => (dispatch, getState) => {
  const component = selectComponent(getState(), { id });

  return fetchPersonalizedGamesJson(type)
    .then((response) => ({
      ...component,
      payload: {
        ...component.payload,
        games: response,
      },
    }))
    .then(compose(dispatch, updateComponent, normaliseComponent));
};

export const getCasinoFilteredGames = (filterTagIdsArr, page = 1) => (dispatch) => {
  dispatch((page === 1 ? resetFilteredGames : fetchFilteredGames)());

  return getFilteredGames(filterTagIdsArr, page)
    .then((result) => {
      const results = normaliseSearchResults(result);

      dispatch(setFilteredGames('filter')(results));
    });
};

export const fetchMore = (filterTagIdsArr) => (dispatch, getState) => {
  const { casinoStore: { filter: { isGamesFetching, nextPage } } } = getState();

  if (!isGamesFetching && nextPage) {
    dispatch(getCasinoFilteredGames(filterTagIdsArr, nextPage));
  }
};
