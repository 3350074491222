import {
  omit, each, compose, partial, property,
} from 'underscore';
import { addGames, removeGames } from 'casino-action-creators/games';
import { addStudios, removeStudios } from 'casino-action-creators/studios';

import {
  selectComponent,
  selectNormComponents,
  selectSearch,
  selectFilteredGames,
} from 'CasinoV2/selectors/casino_selector';

import {
  SET_COMPONENT,
  UPDATE_COMPONENT,
  FETCH_COMPONENTS_INIT,
  RESET_COMPONENTS,
  SET_SEARCH_RESULT,
  SET_FILTERED_GAMES,
  RESET_SEARCH,
} from './ducks/casino_store';

const addEntities = (dispatch, { studios, games }) => {
  if (studios) dispatch(addStudios(studios));
  if (games) dispatch(addGames(games));
};

const removeEntities = (dispatch, { studios, games }) => {
  if (studios) dispatch(removeStudios(studios));
  if (games) dispatch(removeGames(games));
};

export default ({ dispatch, getState }) => (next) => (action) => {
  switch (action.type) {
    case SET_COMPONENT:
    {
      addEntities(dispatch, action.normalisedComponent.entities);
      break;
    }
    case UPDATE_COMPONENT:
    {
      removeEntities(
        dispatch,
        selectComponent(getState(), { id: action?.normalisedComponent.result })?.payload || {},
      );
      addEntities(dispatch, action?.normalisedComponent.entities);
      break;
    }
    case FETCH_COMPONENTS_INIT:
    case RESET_COMPONENTS:
    {
      each(
        selectNormComponents(getState()),
        compose(partial(removeEntities, dispatch), property('payload')),
      );
      break;
    }
    case SET_SEARCH_RESULT:
    {
      // Search is paginated, but when removed is removed at once
      // We will add new games/studios only if they are not added yet
      const {
        studios: currentStudios,
        games: currentGames,
      } = selectSearch(action.component)(getState());
      const { studios, games } = action.normalisedItems.entities;

      addEntities(dispatch, {
        studios: omit(studios, currentStudios),
        games: omit(games, currentGames),
      });
      break;
    }
    case SET_FILTERED_GAMES:
    {
      const {
        studios: currentStudios,
        games: currentGames,
      } = selectFilteredGames(getState());
      const { studios, games } = action.normalisedItems.entities;

      addEntities(dispatch, {
        studios: omit(studios, currentStudios),
        games: omit(games, currentGames),
      });
      break;
    }
    case RESET_SEARCH:
    {
      removeEntities(dispatch, selectSearch(action.component)(getState()));
      break;
    }
    default:
      break;
  }

  next(action);
};
